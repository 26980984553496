import { useState } from 'react';
import operators from '../../assets/img/operators.png';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import ModalComponent from '../home/Modal';

function SolutionsOpearots() {
  const [openModal, setOpenModal] = useState(false);

  return (
    <section className="solutions-wrap">
      <div className="container py-5">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/solutions">Solutions</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              For telecom operators
            </li>
          </ol>
        </nav>
        <h2 className="display-5  pt-xxl-2 mb-4">
          Solutions for telecom operators
        </h2>

        <p>
          All our solutions are developed on the basis of many years of
          experience. We know how important it is that the solution is as
          flexible and convenient as possible for the communication operator.
          Therefore, we carefully consider all the details that may affect the
          operator's convenience.
        </p>
        <div className="row ms-1">
          <div className="col-6">
            <ul className="mb-5">
              <li className="my-3">Project development</li>
              <li className="my-3">Creation and development of scripts</li>
              <li className="my-3">Organization of a hotline</li>
              <li className="my-3">
                Organization of “smart" distribution of incoming calls
              </li>
              <li className="my-3">Conducting cold calls</li>
              <li className="my-3">
                Prompt processing of incoming user requests
              </li>
              <li>Detailed reporting</li>
            </ul>
            <Button
              className="mt-5"
              size="lg"
              onClick={() => setOpenModal(true)}
            >
              Send a request
            </Button>
          </div>

          <img
            className="col-6"
            style={{ maxWidth: 540 }}
            src={operators}
            alt="for-operators"
          />
        </div>
      </div>
      <ModalComponent open={openModal} setOpen={setOpenModal} />
    </section>
  );
}

export default SolutionsOpearots;
