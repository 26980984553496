const buns = [
  {
    icon: require('../../assets/img/earphones.svg').default,
    title: 'Professional application processing',
    text: 'Every employee is involved in your goals and objectives!',
  },
  {
    icon: require('../../assets/img/rocket.svg').default,
    title: 'Detailed reporting',
    text: 'Transparency in the processing of each application',
  },
  {
    icon: require('../../assets/img/lamp.svg').default,
    title: 'Flexibility',
    text: 'Zorra operators speak multiple languages. Easily adapt to new sales scripts',
  },
  {
    icon: require('../../assets/img/settings.svg').default,
    title: '24/7',
    text: 'Processing applications at any time',
  },
  {
    icon: require('../../assets/img/clock.svg').default,
    title: 'Unlimited',
    text: 'There is no limit on incoming requests. Guarantee of processing of each application on the day of receipt',
  },
  {
    icon: require('../../assets/img/clock.svg').default,
    title: 'Experience',
    text: 'Assistance in optimization at all stages of application processing from receipt to sale',
  },
];
function Buns() {
  return (
    <section className="py-2 py-lg-3 py-xl-4 py-xxl-5">
      <div className="container py-5 mt-1 mb-2 mt-sm-2 mb-sm-3 mt-md-3 mb-md-4 my-lg-4 my-xl-5">
        <h2 className="display-5 text-center mb-4 pt-xxl-2">
          Working with us you get:
        </h2>

        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-4">
          <>
            {buns.map((i) => (
              <div className="col" key={i.title}>
                <div className="card border-0 ">
                  <div className="buns-card bg-card card-body text-center rounded-5 py-2 py-lg-4 px-0 p-5">
                    <img
                      className="buns-icon mx-auto"
                      src={i.icon}
                      alt={i.title}
                    />
                    <h3 className="mb-0 px-5">{i.title}</h3>
                    <p className="card-text px-5">{i.text}</p>
                  </div>
                </div>
              </div>
            ))}
          </>
        </div>
      </div>
    </section>
  );
}

export default Buns;
