import { Link } from 'react-router-dom';

function Vacancies() {
  return (
    <section className="vacancies-wrap">
      <div className="container py-5">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Job openings
            </li>
          </ol>
        </nav>
        <h2 className="display-5 pt-xxl-2">Our vacancies</h2>
        <h3 className="pt-xxl-2">Call center operator</h3>
        <p>Responsibilities:</p>
        <ul>
          <li>Receiving incoming calls</li>
          <li>Advising clients on bank products</li>
          <li>Sale of products and services of the bank </li>
        </ul>
        <p>Requirements:</p>
        <ul>
          <li>Confident PC ownership</li>
          <li>Work experience from 1 year </li>
        </ul>
        <p>Conditions:</p>
        <ul>
          <li>
            Work schedule 2/2 (day and night shifts) from 08.00 to 20.00 and
            from 20.00 to 08.00
          </li>
          <li>Official employment</li>
          <li>Salary payments 2 times a month without delay</li>
          <li>Corporate free training. Career opportunities</li>
        </ul>
        <h3 className="pt-4 pt-xxl-4">Assistant Sales Manager</h3>
        <p>Responsibilities:</p>
        <ul>
          <li>Quality control of the department's work</li>
          <li>Distribution of tasks among department employees</li>
          <li>Reporting</li>
        </ul>
        <p>Requirements:</p>
        <ul>
          <li>Confident PC ownership</li>
          <li>Experience in sales from 1 year</li>
          <li>Experience in a managerial position is welcome</li>
        </ul>
        <p>Conditions:</p>
        <ul>
          <li>Work schedule 5/2 from 08.00 to 17.00</li>
          <li>Official employment</li>
          <li>Salary payments 2 times a month without delay</li>
          <li>Corporate free training. Career opportunities</li>
        </ul>
        <h3 className="pt-4 pt-xxl-4">
          Contact email{' '}
          <a className="email-link" href="mailto:job@zorra.ng">
            job@zorra.ng
          </a>
        </h3>
      </div>
    </section>
  );
}

export default Vacancies;
