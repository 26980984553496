import { Routes, Route } from 'react-router-dom';

import Layout from './pages/home/Layout';
import Landing from './pages/home/Landing';
import Vacancies from './pages/Vacancies';
import About from './pages/About';
import Solutions from './pages/solutions/Solutions';
import SolutionsStore from './pages/solutions/SolutionsStore';
import SolutionsOperators from './pages/solutions/SolutionsOperators';
import SolutionsBanking from './pages/solutions/SolutionsBanking';
import './App.scss';

const ROUTES = [
  {
    path: '/',
    element: <Landing />,
  },
  {
    path: 'solutions',
    element: <Solutions />,
  },
  {
    path: 'solutions/for-online-store',
    element: <SolutionsStore />,
  },
  {
    path: 'solutions/for-operators',
    element: <SolutionsOperators />,
  },
  {
    path: 'solutions/for-banking',
    element: <SolutionsBanking />,
  },
  {
    path: 'about',
    element: <About />,
  },
  {
    path: 'vacancies',
    element: <Vacancies />,
  },
];

function App() {
  return (
    <div className="App">
      <Routes>
        
        <Route path="/" element={<Layout />}>
          {ROUTES.map(({ path, element }) => (
            <Route key={path} path={path} element={element} />
          ))}
        </Route>
        
      </Routes>

    </div>
  );
}

export default App;
