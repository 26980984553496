import { Link } from 'react-router-dom';

function Solutions() {
  return (
    <section className="solutions-wrap">
      <div className="container py-5">
        <h2 className="display-5  pt-xxl-2">Our solutions</h2>

        <p>
          Here you can get acquainted with various solutions developed by by our
          company, which will allow you to quickly and easily find the right one
          for your needs. The solutions presented on this page cover various
          fields of activity and categories of clients.
        </p>
        <div className="flex-col-center">
          <Link to="/solutions/for-online-store">
            <div className="bg-primary card border-0 round-4 my-3 card-lg text-center">
              For online stores
            </div>
          </Link>
          <Link to="/solutions/for-operators">
            <div className="bg-primary card border-0 round-4 my-3 card-lg text-center">
              For telecom operators
            </div>
          </Link>
          <Link to="/solutions/for-banking">
            <div className="bg-primary card border-0 round-4 my-3 card-lg text-center">
              For the banking sector
            </div>
          </Link>
        </div>
      </div>
    </section>
  );
}

export default Solutions;
