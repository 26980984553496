const NAV_ITEMS = [
  {
    title: 'Home',
    link: '/',
  },
  {
    title: 'Services',
    hash: '/#services',
  },
  {
    title: 'Prices',
    hash: '/#prices',
  },
  {
    title: 'Solutions',
    link: 'solutions',
  },
  {
    title: 'Coverage map',
    hash: '/#coverage',
  },
  {
    title: 'About us',
    link: 'about',
  },
  {
    title: 'Vacancies',
    link: 'vacancies',
  },
];



export default NAV_ITEMS