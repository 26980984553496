import { useState } from 'react';
import banking from '../../assets/img/banking.png';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import ModalComponent from '../home/Modal';

function SolutionsBanking() {
  const [openModal, setOpenModal] = useState(false);

  return (
    <section className="solutions-wrap">
      <div className="container py-5">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/solutions">Solutions</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Banking sector
            </li>
          </ol>
        </nav>
        <h2 className="display-5  pt-xxl-2 mb-4">
          Solutions for the banking sector
        </h2>

        <p>
          We offer ready-made solutions developed based on the experience of
          various banks and international financial institutions. Our systems
          are not just automate the work of the contact center, but also allow
          you to provide high level of customer service quality.
        </p>
        <div className="row ms-1">
          <div className="col-6">
            <ul className="mb-5">
              <li className="my-3">Project development</li>
              <li className="my-3">Creation and development of scripts</li>
              <li className="my-3">Organization of a hotline</li>
              <li className="my-3">
                Organization of a database of potential customers
              </li>
              <li className="my-3">Conducting cold calls</li>
              <li className="my-3">
                Organization of surveys and their processing
              </li>
              <li className="my-3">
                Informing about new products and special offers
              </li>
              <li className="my-3">Detailed reporting</li>
            </ul>
            <Button
              className="mt-5"
              size="lg"
              onClick={() => setOpenModal(true)}
            >
              Send a request
            </Button>
          </div>

          <img
            className="col-6"
            style={{ maxWidth: 540 }}
            src={banking}
            alt="for-banking"
          />
        </div>
      </div>
      <ModalComponent open={openModal} setOpen={setOpenModal} />
    </section>
  );
}

export default SolutionsBanking;
