import { Link } from 'react-router-dom';

function About() {
  return (
    <section className="about-wrap">
      <div className="container py-5">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              About the company
            </li>
          </ol>
        </nav>
        <h2 className="display-5  pt-xxl-2">About the company</h2>

        <address>
          <div className="my-2">
            <strong>ZORRA TELECOM NIGERIA LIMITED</strong>
          </div>

          <div className="my-1">Registration No. 1759660</div>
          <div className="my-1">
            Address of registration: 50/52, TOYIN STREET, IKEJA, LAGOS STATE
            NIGERIA
          </div>
          <div className="mt-1 mb-5">Data of incorporation: 28 November 2019</div>
        </address>

        <div className="row my-4">
          <div className="col-3 text-bold">2012</div>
          <div className="col-9 history-title">
            <div className="text-separate" />
            June — the company Zorra was founded, consisting at that time of 4
            employees
          </div>
        </div>
        <div className="row my-5">
          <div className="col-3 text-bold">2013</div>
          <div className="col-9 history-title">
            <div className="text-separate" />
            The company is actively developing in the field of call center
            services
          </div>
        </div>
        <div className="row my-5">
          <div className="col-3 text-bold">2015</div>
          <div className="col-9 history-title">
            <div className="text-separate" />
            The company Zorra is outsourcing. Launched its own development of
            funnels and scripts
          </div>
        </div>
        <div className="row my-5">
          <div className="col-3 text-bold">2017</div>
          <div className="col-9 history-title">
            <div className="text-separate" />
            Zorra switches to the status of a contact center. There is an active
            development outgoing calls and request processing
          </div>
        </div>
        <div className="row my-5">
          <div className="col-3 text-bold">2018</div>
          <div className="col-9 history-title">
            <div className="text-separate" />
            Cooperation with the leading telecom operators of the country is
            behind us. Complete modernization of equipment and increase of
            working directions. The coverage map now covers all regions of the
            country
          </div>
        </div>
        <div className="row my-5">
          <div className="col-3 text-bold">2019</div>
          <div className="col-9 history-title">
            <div className="text-separate" />
            Zorra company enters the international level
          </div>
        </div>
        <div className="row my-5">
          <div className="col-3 text-bold">2021</div>
          <div className="col-9 history-title">
            <div className="text-separate" />
            Zorra is becoming a really big company. State employees exceed 500
            people
          </div>
        </div>
        <div className="row my-5">
          <div className="col-3 text-bold">2022</div>
          <div className="col-9 ">...</div>
        </div>
      </div>
    </section>
  );
}

export default About;
