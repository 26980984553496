import Main from './Main';
import Buns from './Buns';
import Offer from './Offer';
import Map from './Map';
import Prices from './Prices';

function Landing() {
  return (
    <>
      <Main />
      <Buns />
      <Offer />
      <Map />
      <Prices />
    </>
  );
}

export default Landing;
