import { useState } from 'react';
import store from '../../assets/img/store.png';
import Button from 'react-bootstrap/Button';
import { Link } from 'react-router-dom';
import ModalComponent from '../home/Modal';

function Solutions() {
  const [openModal, setOpenModal] = useState(false);

  return (
    <section className="solutions-wrap">
      <div className="container py-5">
        <nav aria-label="breadcrumb">
          <ol className="breadcrumb">
            <li className="breadcrumb-item">
              <Link to="/">Home</Link>
            </li>
            <li className="breadcrumb-item">
              <Link to="/solutions">Solutions</Link>
            </li>
            <li className="breadcrumb-item active" aria-current="page">
              Online stores
            </li>
          </ol>
        </nav>
        <h2 className="display-5  pt-xxl-2 mb-4">
          Solutions for online stores
        </h2>

        <p>
          Zorra company offers ready-made solutions for operators contact
          centers and call centers for online store maintenance. This ready-made
          solutions that we have developed specifically for our customers, so
          that they can start as quickly and efficiently as possible work.
        </p>
        <div className="row">
          <div className="col-6">
            <ul className="mb-5">
              <li className="my-3">Project development</li>
              <li className="my-3">Creation and development of scripts</li>
              <li className="my-3">Organization of a hotline</li>
              <li className="my-3">Round-the-clock call reception</li>
              <li className="my-3">Prompt order processing</li>
              <li className="my-3">Detailed reporting</li>
            </ul>
            <Button
              className="mt-5"
              size="lg"
              onClick={() => setOpenModal(true)}
            >
              Send a request
            </Button>
          </div>

          <img
            className="col-6"
            style={{ maxWidth: 540 }}
            src={store}
            alt="for-store"
          />
        </div>
      </div>
      <ModalComponent open={openModal} setOpen={setOpenModal} />
    </section>
  );
}

export default Solutions;
