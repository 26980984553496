import { useState } from 'react';
import logo from '../../assets/img/logo.png';
import girl from '../../assets/img/main-pic.png';
import Fade from 'react-bootstrap/Fade';


function Main() {
  const [open, setOpen] = useState(false);

  return (
    <section className="main-container mx-auto text-center py-2 py-lg-3 py-xl-4 py-xxl-5">
      <div className="container text-center accordion">
        <div style={{ maxWidth: 370 }} className="mx-auto">
          <img src={girl} alt="Support" className="my-4" />
        </div>

        <div style={{ maxWidth: 280 }} className="mx-auto">
          <img src={logo} alt="ZORRA TELECOM NIGERIA" className="my-4" />
        </div>

        <h3 className="display-5 text-center pt-xxl-2">
          Choosing Zorra — you choose quality!
        </h3>

        <div className="fs-3 mt-4 mb-2">
          10 years of providing contact center outsourcing services!
        </div>

        <button
          className={`accordion-button mx-auto ${open ? '' : 'collapsed'}`}
          type="button"
          data-bs-toggle="collapse"
          onClick={() => setOpen(!open)}
          aria-controls="example-collapse-text"
          aria-expanded={open}
        ></button>

        <Fade in={open}>
          <div
            id="example-collapse-text"
            className="mx-auto fs-4"
            style={{ maxWidth: 1150, display: open ? 'block' : 'none' }}
          >
            Contact center outsourcing is a service that allows companies to
            reduce the cost of maintaining their own contact center and at the
            same time improve the quality of customer service by attracting
            external specialists with experience and necessary skills. Contact
            Center Zorra Telecom is a combination of employees, equipment and
            software that provide timely and high-quality customer service
          </div>
        </Fade>
      </div>
    </section>
  );
}

export default Main;
