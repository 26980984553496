import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Fade from 'react-bootstrap/Fade';
import ModalComponent from './Modal';

const list = [
  {
    icon: require('../../assets/img/offer1.png'),
    title: 'Launching projects of any complexity within a week',
  },
  {
    icon: require('../../assets/img/offer2.png'),
    title: 'Staff of more than 100 employees for a period of one month',
    items: ['Support Lines', 'Sales Department', 'Hotlines'],
  },
  {
    icon: require('../../assets/img/offer3.png'),
    title: 'High-tech equipment',

    items: ['Call Recording', 'Perfect communication quality', '100% privacy'],
  },
];

function Offer() {
  const [open, setOpen] = useState(false);
  const [openModal, setOpenModal] = useState(false);

  return (
    <section
      id="services"
      className="mx-auto blue-container text-center py-2 py-lg-3 py-xl-4 py-xxl-5"
    >
      <div className="container text-center py-5 mt-1 mb-2 mt-sm-2 mb-sm-3 mt-md-3 mb-md-4 my-lg-4 my-xl-5">
        <h2 className="display-5 text-center pt-xxl-2 mb-4">We offer:</h2>

        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-4 mt-4 mb-5 bg-white accordion rounded-4">
          {list.map((i, index) => (
            <div className="col card border-0" key={i.title}>
              <img
                src={i.icon}
                alt={i.title}
                style={{ maxWidth: 100 }}
                className="mx-auto mb-5"
              />
              <div className="px-5 mx-5"> {i.title}</div>

              <Fade in={open}>
                <div
                  id="example-collapse-text"
                  className="mx-auto mt-4"
                  style={{ maxWidth: 1150, display: open ? 'block' : 'none' }}
                >
                  {i.items && (
                    <ul style={{ listStyleType: 'none' }} className="p-1">
                      {i.items?.map((l) => (
                        <li key={l}>
                          <span className="pe-2" style={{ fontSize: 10 }}>
                            &#9679;
                          </span>
                          {l}
                        </li>
                      ))}
                    </ul>
                  )}
                </div>
              </Fade>

              {index === 1 && (
                <button
                  className={`accordion-button mx-auto ${
                    open ? '' : 'collapsed'
                  }`}
                  type="button"
                  data-bs-toggle="collapse"
                  onClick={() => setOpen(!open)}
                  aria-controls="example-collapse-text"
                  aria-expanded={open}
                ></button>
              )}
            </div>
          ))}
        </div>

        <Button className="mt-5" size="lg" onClick={() => setOpenModal(true)}>
          Send a request
        </Button>
      </div>
      <ModalComponent open={openModal} setOpen={setOpenModal} />
    </section>
  );
}

export default Offer;
