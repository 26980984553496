import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import ModalComponent from './Modal';

const callList = [
  {
    icon: require('../../assets/img/person1.png'),
    title: 'up to 1000 calls per month',
    price: 'from 390 000',
  },
  {
    icon: require('../../assets/img/person2.png'),
    title: 'from 1000 to 5000 calls per month',
    price: '170 000 - 390 000',
  },
  {
    icon: require('../../assets/img/person3.png'),
    title: 'more than 5000 calls per month',
    price: 'from 170 000',
  },
];

const requestList = [
  {
    icon: require('../../assets/img/person4.png'),
    title: 'up to 1000 applications per month',
    price: 'from 500 000',
  },
  {
    icon: require('../../assets/img/person5.png'),
    title: 'from 1000 to 5000 applications per month',
    price: '250 000 - 500 000',
  },
  {
    icon: require('../../assets/img/person6.png'),
    title: 'more than 5000 applications per month',
    price: 'from 200 000',
  },
];

function Prices() {
  const [openModal, setOpenModal] = useState(false);

  return (
    <section
      id="prices"
      className="blue-container mx-auto text-center py-2 py-lg-3 py-xl-4 py-xxl-5"
    >
      <div className="container text-center py-5 mt-1 mb-2 mt-sm-2 mb-sm-3 mt-md-3 mb-md-4 my-lg-4 my-xl-5">
        <h2 className="display-5 text-center pt-xxl-2 mb-4">Our prices:</h2>
        <p className="fs-3">Call Processing</p>
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-4 mb-5">
          {callList.map((i) => (
            <div className="col" key={i.title}>
              <div className="bg-white text-center rounded-5 pb-2 pb-lg-2 px-0">
                <div>
                  <img src={i.icon} alt={i.title} />
                </div>
                <p className="fs-6 mt-4 mb-1 ">{i.title}</p>
                <h4 className="fs-2">{i.price} &#8358;</h4>
              </div>
            </div>
          ))}
        </div>
        <p className="fs-3 mt-4">Requests processing</p>
        <div className="row row-cols-1 row-cols-sm-2 row-cols-md-3 g-4 mb-5">
          {requestList.map((i) => (
            <div className="col" key={i.title}>
              <div className="bg-white text-center rounded-5 pb-2 pb-lg-2 px-0">
                <div>
                  <img src={i.icon} alt={i.title} />
                </div>
                <p className="fs-6 mt-4 mb-1">{i.title}</p>
                <h4 className="fs-2">{i.price} &#8358;</h4>
              </div>
            </div>
          ))}
        </div>
        <div className="mt-4">
          <Button className="mt-4" size="lg" onClick={() => setOpenModal(true)}>
            Send a request
          </Button>
        </div>
      </div>
      <ModalComponent open={openModal} setOpen={setOpenModal} />
    </section>
  );
}

export default Prices;
