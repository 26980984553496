import { Link } from 'react-router-dom';
import logo from '../../assets/img/logo.png';
import { HashLink } from 'react-router-hash-link';
import NAV_ITEMS from '../../assets/const';

function Footer() {
  return (
    <footer className="navbar navbar-expand-lg bg-white px-3 mx-3 my-5">
      <div className="container footer">
        <div style={{ maxWidth: 300, fontSize: 12 }}>
          <div style={{ width: 190 }} className="me-1 mb-4">
            <Link to="/">
              <img src={logo} alt="ZORRA TELECOM NIGERIA" />
            </Link>
          </div>
          <address>
            <div className="my-2">
              <strong>ZORRA TELECOM NIGERIA LIMITED</strong>
            </div>

            <div className="my-1">Registration No. 1759660</div>
            <div className="my-1">
              Address of registration: 50/52, TOYIN STREET, IKEJA, LAGOS STATE
              NIGERIA
            </div>
            <div className="my-1">Data of incorporation: 28 November 2019</div>
          </address>
        </div>

        {NAV_ITEMS.map((i) => (
          <div style={{ display: 'flex' }} key={i.title}>
            {i.hash ? (
              <HashLink smooth className="nav-link" to={i.hash}>
                {i.title}
              </HashLink>
            ) : (
              <Link className="nav-link" to={i.link}>
                {i.title}
              </Link>
            )}

            <div className="separate ms-4" />
          </div>
        ))}
        <a
          className="email-link email-link--no-underline"
          href="mailto:sale@zorra.ng"
        >
          sale@zorra.ng
        </a>
      </div>
    </footer>
  );
}

export default Footer;
